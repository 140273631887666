import r from '../utils/request'

export const sentConfirmationCode = (params) => {
  return r.postWithToast('/subscribe', params, {
    success: {
      render({ data }) {
        return data?.data === true
          ? 'Confirmation code sent via text to your phone'
          : "You've subscribed to this theme"
      },
    },
  })
}

export const confirmSubscription = (params) => {
  return r.postWithToast('/confirm-subscription', params, { success: 'Registration was confirmed' })
}

export const getThemeConfigs = (themeId) => {
  return r.get(`/themes/${themeId}/registration-data`)
}
