import { connect } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { Outlet } from 'react-router-dom'

import { APP_ELEMENTS } from '../../utils/constants'
import { LoadingIcon } from '../SvgIcon'
import AdminMenu from '../AdminMenu'

import './styles.css'

const LayoutAdmin = ({ children, loadingInfo, className = '', openMenu, classNamePage = '' }) => {
  return (
    <section className={`adminLayout ${className}`}>
      <Helmet title={`${APP_ELEMENTS.NAME}: Admin Panel`} />
      <AdminMenu />
      <div className={`innerInfoSection ${classNamePage} ${!openMenu ? 'infoCloseMenu' : ''}`}>
        {children ?? <Outlet />}
        {loadingInfo && (
          <div className="innerLoadingAdmin">
            <LoadingIcon className="loadingAdmin" />
          </div>
        )}
      </div>
    </section>
  )
}

export default connect(({ ui }) => ({
  openMenu: ui.openAdminMenu,
  loadingInfo: ui.loadingAdminDataProject,
}))(LayoutAdmin)
