import { useMemo, useState } from 'react'
import { isEmpty } from 'lodash'
import { Dropdown } from 'react-bootstrap'

import { getAvailableTimezones } from '../utils/functions/time'
import CustomToggle from './CustomToggle'
import DropdownInput from './DropdownInput'

const DropdownTimezone = ({
  id,
  value,
  error,
  onChange,
  label = '',
  titleClass = '',
  disabled = false,
  innerDropdown = '',
  customDropdownMenu = '',
  title = 'Select timezone',
}) => {
  const [searchValue, setSearchValue] = useState('')
  const availableTimezones = useMemo(getAvailableTimezones, [])
  const showsTitle = useMemo(
    () =>
      availableTimezones.find(({ name, title }) => name === value.title || value.title === title)
        ?.title || title,
    [value]
  )

  const filteredTimezone = useMemo(
    () =>
      availableTimezones?.filter(
        ({ title }) => !searchValue || title.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [searchValue]
  )

  return (
    <div className={`innerDropdown ${innerDropdown}`}>
      {!isEmpty(label) && (
        <label htmlFor={id} className="inputLabel">
          {label}
        </label>
      )}
      <Dropdown id={id}>
        <Dropdown.Toggle disabled={disabled} as={CustomToggle}>
          <DropdownInput
            error={error}
            disabled={disabled}
            titleClass={titleClass}
            title={showsTitle}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu className={`customDropdownMenu pt-0 ${customDropdownMenu}`}>
          <input
            type="text"
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search..."
            className="inputSearchDropdown"
          />
          {filteredTimezone?.map((item, index) => (
            <Dropdown.Item
              key={index}
              className="itemMenu"
              onClick={() => {
                onChange(item)
              }}
            >
              {item.title}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default DropdownTimezone
