import randomColor from 'randomcolor'

import { SETTINGS_RANDOM_COLOR } from '../utils/constants'
import { AutoRenew } from './SvgIcon'
import Tag from './Tag'
import CustomInput from './CustomInput'
import CustomCheckbox from './CustomCheckbox'

const InputTag = ({ value, onChange, error, placeholder = '', showPreview = true }) => {
  const randomNewColor = () => {
    const color = randomColor(SETTINGS_RANDOM_COLOR)
    const newValue = { ...value, color }
    onChange(newValue)
  }

  const changeInfo = (e, key) => {
    const newValue = { ...value, [key]: e.target.value }
    onChange(newValue)
  }

  return (
    <div>
      {showPreview && <Tag className="previewTag" {...value} name={value.name || 'Preview'} />}
      <div className="innerTagInner">
        <div>
          <CustomInput
            type="text"
            label="Name"
            className={`tagName ${error ? 'errorTagName' : ''}`}
            value={value.name}
            onChange={(e) => changeInfo(e, 'name')}
            placeholder={placeholder}
          />
        </div>
        <div>
          <label className="inputLabel ms-0">Color</label>
          <div className="innerColorsChanges">
            <input
              type="color"
              value={value?.color}
              onChange={(e) => changeInfo(e, 'color')}
              className="tagColor"
            />
            <AutoRenew
              fill="#FFFFFF"
              size={40}
              onClick={randomNewColor}
              className="arrowRepeatTag"
              style={{ backgroundColor: value?.color }}
            />
          </div>
        </div>
      </div>
      <CustomCheckbox
        value={value?.isVisibleToUser}
        className="ps-0"
        label="Display this tag on the user interface"
        onChange={(isVisibleToUser) => onChange({ ...value, isVisibleToUser })}
      />
    </div>
  )
}

export default InputTag
