import {
  ERROR_CODE_BAD_REQUEST,
  ERROR_CODE_NOT_FOUND,
  ERROR_CODE_SERVER_ERROR,
  ERROR_CODE_THROTTLING,
  ERROR_CODE_VALIDATION,
} from '../../constants'

export const handleErrorLogin = ({ status }) => {
  switch (status) {
    case ERROR_CODE_THROTTLING:
      return 'Too many requests!'
    case ERROR_CODE_NOT_FOUND:
      return 'User not found!'
    case ERROR_CODE_VALIDATION:
      return 'Invalid phone number'
    case ERROR_CODE_BAD_REQUEST:
      return 'You have stopped all SMS messages from us. In order to logic, please text START to the number you received the message from.'
    default:
      return status >= ERROR_CODE_SERVER_ERROR ? 'Server error!' : 'Unexpected error'
  }
}
