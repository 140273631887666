import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { isNil } from 'lodash'

import { SUBSCRIPTION_STATUSES } from '../../../utils/constants'
import { getThemeDetails } from '../../../api/user'
import { LoadingIcon, SubscriptionSettings } from '../../../components/SvgIcon'
import IconButton from '../../../components/IconButton'
import ModalSubscription from '../../../components/Modals/ModalSubscription'
import SettingsBlock from './components/SettingsBlock'
import ContentBlock from './components/ContentBlock'
import CalendarBlock from './components/CalendarBlock'
import FavoriteBlock from './components/FavoriteBlock'

import './styles.css'

const ThemeDetails = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [showModalSubscription, setShowModalSubscription] = useState(false)
  const queryClient = useQueryClient()
  const { themeId } = useParams()

  const { data: themeDetails, isLoading: isLoadingThemeDetails } = useQuery({
    queryKey: ['themeDetails', themeId],
    queryFn: () => getThemeDetails(themeId),
    enabled: !isNil(themeId),
  })
  const { theme, status, details, deliveryTime, createdAt, subscriptableType } = useMemo(
    () => themeDetails?.data?.subscription ?? {},
    [themeDetails]
  )

  return (
    <>
      <section className="d-flex justify-content-between align-items-center">
        <h3 className="titleProfileSecond text-center text-md-start m-0">{theme?.name}</h3>
        <IconButton
          buttonClassName="bg-transparent p-0 animationPulse rounded-circle"
          placement="autoHorizontal"
          renderTooltipContent={() => <p>View Theme Details</p>}
          onClick={() => setShowModalSubscription(true)}
        >
          <SubscriptionSettings
            size={27}
            fill="var(--swatch--cta-blue)"
            className="subscriptionSettingsBtn"
          />
        </IconButton>
      </section>
      <div className="tabsList mt-3 justify-content-start">
        {['Content', 'Calendar', 'Favorite'].map((tabName, index) => (
          <p
            key={tabName}
            onClick={() => setActiveTabIndex(index)}
            className={`tab ${index === activeTabIndex ? 'active' : ''}`}
          >
            {tabName}
          </p>
        ))}
        {status !== SUBSCRIPTION_STATUSES.ABANDONED_CART && (
          <p
            onClick={() => setActiveTabIndex(3)}
            className={`tab ${3 === activeTabIndex ? 'active' : ''}`}
          >
            Settings
          </p>
        )}
      </div>
      <ContentBlock isActiveBlock={activeTabIndex === 0} themeId={themeId} />
      <CalendarBlock
        isActiveBlock={activeTabIndex === 1}
        themeId={themeId}
        providersDetails={themeDetails?.data?.socialCalendarProviders}
      />
      <FavoriteBlock isActiveBlock={activeTabIndex === 2} themeId={themeId} />
      <SettingsBlock isActiveBlock={activeTabIndex === 3} themeId={themeId} />
      <ModalSubscription
        show={showModalSubscription}
        subscriptionInfo={{
          status,
          name: theme?.name,
          details,
          themeId,
          deliveryTime,
          startSubscriptionAt: createdAt,
          image: `data:image/png;base64, ${theme?.contact?.image}`,
          subscriptableType,
          price: { old: details?.oldPrice, new: details?.newPrice },
        }}
        changeVisibleInfo={() =>
          queryClient.invalidateQueries({ queryKey: ['themeDetails', themeId] })
        }
        onHide={() => setShowModalSubscription(false)}
      />
      {isLoadingThemeDetails && (
        <div className="innerLoader">
          <LoadingIcon className="loaderIcon" />
        </div>
      )}
    </>
  )
}

export default ThemeDetails
