import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import moment from 'moment-timezone'
import { Dropdown, Table } from 'rsuite'
import ReactPaginate from 'react-paginate'

import {
  DATE_FORMAT_MDY,
  PHONE_PATTERN_REPLACE,
  REPLACEMENT_PHONE,
  SETTINGS_PAGINATION,
  // TIME_FORMAT_12H,
  HISTORY_BACK,
} from '../../../utils/constants'
import { routes } from '../../../router/routes'
import {
  deleteUser,
  getUserInfoById,
  getAllUserSubscriptions,
  cancelImmediatelyAllSubscriptions,
} from '../../../api/admin/user'
import { Bin, Block, LoadingIcon, ThreeDotsVertical } from '../../../components/SvgIcon'
import { SubscriptionStatus } from '../../../components/Statuses'
import UtmParams from '../../../components/UtmParams'
import ChatWindow from '../../../components/ChatWindow'
import BackButton from '../../../components/BackButton'
import ModalConfirm from '../../../components/Modals/ModalConfirm'
import ContentHistory from '../../../components/ContentHistory'
import TableUserActivity from '../../../components/TableUserActivity'
import NotesPopover from './components/NotesPopover'

import styles from './User.module.css'
import './styles.css'

const User = () => {
  const [showModalUserDelete, setShowModalUserDelete] = useState(false)
  const [showModalCancelSubscriptions, setShowModalCancelSubscriptions] = useState(false)
  const [selectedThemesPage, setSelectedThemesPage] = useState(1)
  const { userId } = useParams()
  const navigation = useNavigate()
  const queryClient = useQueryClient()

  const { data: userInfo, isLoading: loadingUserInfo } = useQuery({
    queryKey: ['userInfo', userId],
    queryFn: () => getUserInfoById(userId),
    select: ({ data }) => {
      const { timezoneOffset, timezoneName, createdAt } = data
      const birthdate = data?.birthdate ?? ''
      const phone = data?.phone?.replace(PHONE_PATTERN_REPLACE, REPLACEMENT_PHONE)
      const timezoneInfo = `${timezoneName},  (UTC ${
        timezoneOffset >= 0 ? '+' : ''
      }${timezoneOffset})`
      const fullName = `${data?.firstName || ''} ${data?.lastName || ''}`
      const lastDateInfoAboutSubscription = moment
        .utc(data?.endSubscription)
        .format(DATE_FORMAT_MDY)
      const subscribedAt = moment.utc(createdAt).format(DATE_FORMAT_MDY)
      return {
        ...data,
        phone,
        fullName,
        birthdate,
        timezoneInfo,
        subscribedAt,
        lastDateInfoAboutSubscription,
        timezone: {
          timezoneOffset: timezoneOffset,
          timezoneName: timezoneName,
        },
      }
    },
  })

  const {
    data: userThemes,
    isPending: isPendingUserThemes,
    isFetching: isFetchingUserThemes,
  } = useQuery({
    queryKey: ['userThemes', userId, selectedThemesPage],
    queryFn: () => getAllUserSubscriptions({ userId, size: 5, page: selectedThemesPage }),
    placeholderData: keepPreviousData,
    select: ({ data }) => {
      const formattedThemesData = data?.data?.map(({ createdAt, ...item }) => ({
        ...item,
        date: moment.utc(createdAt).format(DATE_FORMAT_MDY),
        // deliveryTime: moment.utc(createdAt).format(TIME_FORMAT_12H),
      }))

      return { ...data, data: formattedThemesData }
    },
  })

  const mutationUserDelete = useMutation({
    mutationKey: ['userDelete', userId],
    mutationFn: deleteUser,
    onSuccess: () => navigation(routes.users),
  })
  const mutationCancelSubscriptions = useMutation({
    mutationKey: ['userCancelSubscriptions', userId],
    mutationFn: cancelImmediatelyAllSubscriptions,
    onSuccess: () => {
      setShowModalCancelSubscriptions(false)
      queryClient.invalidateQueries({ queryKey: ['userThemes', userId, selectedThemesPage] })
    },
  })

  return (
    <div className="userAdminPage mt-4">
      <div className="d-flex align-items-center gap-2 justify-content-between border-bottom pb-4 mb-3">
        <div className="d-flex align-items-center gap-2">
          <h1 className={styles.userName}>{userInfo?.fullName}</h1>
          <NotesPopover userName={userInfo?.fullName} />
          {!userInfo?.canSendMessage && (
            <div className="blockStatus">
              <Block size={20} fill="var(--persian-red)" />
              <span>Blocked</span>
            </div>
          )}
        </div>
        <Dropdown
          renderToggle={(props, ref) => <ThreeDotsVertical size={25} {...props} ref={ref} />}
          placement="bottomEnd"
        >
          <Dropdown.Item onClick={() => setShowModalCancelSubscriptions(true)}>
            Cancel all subscriptions
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setShowModalUserDelete(true)}>
            <Bin fill="var(--persian-red)" size={20} className="itemIconDropdownMenu" />
            Delete user
          </Dropdown.Item>
        </Dropdown>
      </div>
      <div className="d-flex align-items-start mb-3">
        <div className="verticalBlockSubscriptionInfo">
          <p className="label">Phone</p>
          <p className="value">{userInfo?.phone}</p>
        </div>
        <div className="verticalBlockSubscriptionInfo">
          <p className="label">Timezone</p>
          <p className="value">{userInfo?.timezoneInfo}</p>
        </div>
        <div className="verticalBlockSubscriptionInfo">
          <p className="label">Joined at</p>
          <p className="value">{userInfo?.subscribedAt}</p>
        </div>
      </div>
      <div className="flex-grow-1">
        <div className="mb-5">
          <ContentHistory userId={userId} />
        </div>
        <div className={`${styles.innerTableUserThemes} mb-5`}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <p className={`${styles.titleBlock} mb-0`}>User themes</p>
            <ReactPaginate
              {...SETTINGS_PAGINATION}
              pageCount={userThemes?.lastPage || 1}
              marginPagesDisplayed={-1}
              pageRangeDisplayed={-1}
              containerClassName="d-flex align-items-stretch gap-2"
              onPageChange={({ selected }) => setSelectedThemesPage(selected + 1)}
            />
          </div>
          <Table
            loading={isPendingUserThemes || isFetchingUserThemes}
            data={userThemes?.data}
            autoHeight
            wordWrap="break-word"
            className={styles.tableUserThemes}
            rowClassName={`${styles.tableUserThemesRow} cursorPointer`}
            onRowClick={({ themeId }) => navigation(`theme/${themeId}`, { state: userInfo })}
            id="table-user-themes"
          >
            <Table.Column verticalAlign="middle" width={220}>
              <Table.HeaderCell className="headColumn">Name</Table.HeaderCell>
              <Table.Cell dataKey="themeName" />
            </Table.Column>
            {/*<Table.Column verticalAlign="middle" align="center" width={140}>*/}
            {/*  <Table.HeaderCell className="headColumn">Delivery time</Table.HeaderCell>*/}
            {/*  <Table.Cell dataKey="deliveryTime" />*/}
            {/*</Table.Column>*/}
            <Table.Column verticalAlign="middle" minWidth={200} flexGrow={1}>
              <Table.HeaderCell className="headColumn">Source</Table.HeaderCell>
              <Table.Cell dataKey="source">
                {({ utmCampaign, utmMedium, utmSource }) => (
                  <UtmParams utmParams={{ utmCampaign, utmMedium, utmSource }} />
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column verticalAlign="middle" minWidth={200} flexGrow={1.3}>
              <Table.HeaderCell className="headColumn">Status</Table.HeaderCell>
              <Table.Cell dataKey="status">{(data) => <SubscriptionStatus {...data} />}</Table.Cell>
            </Table.Column>
            <Table.Column verticalAlign="middle" align="center" width={130}>
              <Table.HeaderCell className="headColumn">Joined at</Table.HeaderCell>
              <Table.Cell dataKey="date" />
            </Table.Column>
          </Table>
        </div>

        <div className="mt-4">
          <p className={styles.titleBlock}>Recent Activity</p>
          <TableUserActivity />
        </div>
      </div>
      <ChatWindow isBlocked={userInfo?.hasBlockedTwilio} />
      {loadingUserInfo && (
        <div className={styles.innerLoadingUserPage}>
          <LoadingIcon className="loaderUserData" />
        </div>
      )}
      <ModalConfirm
        title="Confirm User Deletion"
        text={`Are you sure you want to delete ${userInfo?.firstName}? This action cannot be undone.`}
        textButton="Delete User"
        show={showModalUserDelete}
        onHide={() => setShowModalUserDelete(false)}
        handleClickButton={() => mutationUserDelete.mutate({ userId })}
      />
      <ModalConfirm
        title="Confirm Cancel Subscriptions"
        text={`You are about to cancel all active subscriptions for this user immediately. This action will revoke the user's access to all premium features and content right away, and they will not be charged further. \n Are you sure you want to proceed with this immediate cancellation? This action cannot be undone.`}
        textButton="Confirm"
        show={showModalCancelSubscriptions}
        customClassName="modalCancelAllSubscriptions"
        onHide={() => setShowModalCancelSubscriptions(false)}
        handleClickButton={() => mutationCancelSubscriptions.mutate(userId)}
      />
      <BackButton route={HISTORY_BACK} />
    </div>
  )
}

export default User
