import { isEmpty } from 'lodash'
import { GoogleOAuthProvider } from '@react-oauth/google'

import useGoogleAuth from '../utils/hooks/useGoogleAuth'
import { GoogleCalendar, LoadingIcon } from './SvgIcon'

const GoogleAuthButton = ({ loadSync, isSelected, onDisconnect, onComplete }) => {
  const GoogleSwitch = () => {
    const { authWithGoogle, loading } = useGoogleAuth({ onComplete })

    const buttonTooltip = isSelected
      ? 'Remove all events from Google calendar'
      : 'Sync with your Google calendar'
    const buttonText = isSelected ? 'Disconnect Google Calendar' : 'Connect Google Calendar'

    const isLoading = loading || loadSync
    const Icon = isLoading ? LoadingIcon : GoogleCalendar

    return (
      <button
        disabled={isLoading}
        title={buttonTooltip}
        className={`buttonAccess ${isSelected && 'selectedProvider'}`}
        onClick={() => (isSelected ? onDisconnect(authWithGoogle) : authWithGoogle())}
      >
        {buttonText}
        <Icon size={20} className={`loadingSyncCalendar ${isLoading ? 'userSpinnerLight' : ''}`} />
      </button>
    )
  }

  if (isEmpty(process.env.REACT_APP_GOOGLE_CLIENT_ID)) {
    return null
  }

  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      cookiePolicy={'single_host_origin'}
    >
      <GoogleSwitch />
    </GoogleOAuthProvider>
  )
}

export default GoogleAuthButton
