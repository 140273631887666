import r from '../../utils/request'

export const getThemeDeliveryConfigs = (params) => {
  return r.get(`/admin/themes/${params.themeId}/delivery-config`, params)
}

export const updateDeliveryConfig = (params) => {
  return r.putWithToast(`/admin/themes/${params.themeId}/delivery-config`, params, {
    success: `Delivery configs was changed for ${params.pipelineName} pipeline`,
  })
}
