import { Link } from 'react-router-dom'

import { APP_ELEMENTS } from '../../../../utils/constants'
import { routes } from '../../../../router/routes'
import ProfileInfo from '../ProfileInfo'

import './styles.css'

const Header = () => {
  return (
    <header className="header containerUserLayout">
      <Link to={routes.userHome}>
        <img className="logoHeader" src={APP_ELEMENTS.DARK_LOGO} alt="Logo" />
      </Link>
      <ProfileInfo />
    </header>
  )
}

export default Header
