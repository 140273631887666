import { useCallback, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query'
import { isEmpty, isNil } from 'lodash'
import { Table } from 'rsuite'

import {
  MESSAGE_TYPES,
  DATE_FORMAT_WITH_TIME,
  SIZE_PAGINATE_MESSAGES,
} from '../../../../utils/constants'
import { deleteMessage, reorderMessagePipeline } from '../../../../api/admin/messages'
import { Plus, TimeFilled } from '../../../../components/SvgIcon'
import { ActionCell, DateCell, MessageCell } from '../../../../components/Table'
import ModalConfirm from '../../../../components/Modals/ModalConfirm'
import DragRow from '../../../../components/Table/components/DragRow'
import IconButton from '../../../../components/IconButton'

const Pipeline = ({
  getMessagesInfo,
  label,
  withContent,
  tableKey,
  withPagination,
  enableDND,
  dateType,
  requestOptions,
  showModalDeliverySettings,
  deleteMessageRoute = deleteMessage,
  onClickIconTime = () => {},
}) => {
  const [selectedPage, setSelectedPage] = useState(1)
  const [selectedDeleteId, setSelectedDeleteId] = useState(null)
  const { programId, themeId } = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const {
    data: pipelineInfo,
    isLoading,
    isFetching,
  } = useQuery({
    queryFn: () =>
      getMessagesInfo({
        id: themeId,
        page: 1,
        size: SIZE_PAGINATE_MESSAGES * selectedPage,
        ...requestOptions,
      }),
    queryKey: [requestOptions.type, requestOptions.messageType, themeId, selectedPage],
    select: ({ data }) => data,
    placeholderData: keepPreviousData,
  })

  const totalResult = useMemo(
    () => pipelineInfo?.pagination?.total ?? pipelineInfo?.total,
    [pipelineInfo]
  )
  const lastPage = useMemo(
    () => pipelineInfo?.pagination?.lastPage ?? pipelineInfo?.lastPage,
    [pipelineInfo]
  )

  const onIconEdit = useCallback(
    ({ id: messageId }) => {
      navigate({
        pathname: `/admin/message/${programId}/${themeId}/${messageId}`,
        search: `?pipelineType=${requestOptions.type}&messageType=${requestOptions.messageType}`,
      })
    },
    [programId, themeId, requestOptions]
  )

  const handleReorder = useCallback(
    (messageId, hoverMessageId) => {
      if (messageId === hoverMessageId) return

      const newMessageOrder =
        pipelineInfo?.messages.findIndex(({ id }) => id === hoverMessageId) + 1

      return reorderMessagePipeline({ newOrder: newMessageOrder, id: messageId }).then(() => {
        queryClient.invalidateQueries({
          queryKey: [requestOptions.type, requestOptions.messageType, themeId],
        })
      })
    },
    [programId, themeId, requestOptions, pipelineInfo]
  )

  const onIconDelete = useCallback(({ id }) => {
    setSelectedDeleteId(id)
  }, [])

  const confirmDelete = useCallback(
    (messageId) => {
      return deleteMessageRoute(messageId)
        .then(() => setSelectedDeleteId(null))
        .then(() =>
          queryClient.invalidateQueries({
            queryKey: [requestOptions.type, requestOptions.messageType, themeId],
          })
        )
        .finally(() => setSelectedDeleteId(null))
    },
    [themeId]
  )

  return (
    <div
      id={`table-${requestOptions.type}-${requestOptions.messageType}`}
      className="innerTableMessages"
    >
      <div className="d-flex align-items-center justify-content-between mb-2">
        <h3>{label}</h3>
        <div className="d-flex align-items-center gap-2">
          {showModalDeliverySettings && (
            <IconButton
              onClick={() => onClickIconTime(requestOptions)}
              renderTooltipContent={() => (
                <p>
                  Set delivery preferences for scheduling messages in <b>{label}</b>
                </p>
              )}
            >
              <TimeFilled fill="var(--catalina-blue)" size={25} />
            </IconButton>
          )}
          {withPagination || isEmpty(pipelineInfo?.messages) ? (
            <Link
              className="defaultButton"
              to={`/admin/message/${programId}/${themeId}/?pipelineType=${requestOptions.type}&messageType=${requestOptions.messageType}`}
            >
              <Plus fill="var(--catalina-blue)" size={20} />
              Add message
            </Link>
          ) : null}
        </div>
      </div>
      <Table
        loading={isLoading || isFetching}
        data={pipelineInfo?.messages}
        autoHeight
        className="themeMessageTable"
        wordWrap
        renderRow={(children, rowData) => {
          return enableDND ? (
            <DragRow
              key={rowData?.id}
              rowData={rowData}
              id={rowData?.id}
              acceptDragKey={tableKey}
              onDrop={handleReorder}
            >
              {children}
            </DragRow>
          ) : (
            children
          )
        }}
      >
        {withPagination && (
          <Table.Column width={70} verticalAlign="middle" align="center">
            <Table.HeaderCell className="headColumn">#</Table.HeaderCell>
            <Table.Cell className={enableDND ? 'cursorDrag' : ''} dataKey="order" />
          </Table.Column>
        )}
        <Table.Column verticalAlign="middle" minWidth={200} flexGrow={1}>
          <Table.HeaderCell className="headColumn">Message</Table.HeaderCell>
          <MessageCell hasContent={withContent} contentCountKey="contentsCount" dataKey="body" />
        </Table.Column>
        {!isNil(dateType) && (
          <>
            {dateType === MESSAGE_TYPES.ABSOLUTE && (
              <Table.Column width={240} verticalAlign="middle" align="center">
                <Table.HeaderCell className="headColumn">Delivery date</Table.HeaderCell>
                <DateCell dateFormat={DATE_FORMAT_WITH_TIME} dataKey="absoluteDate" />
              </Table.Column>
            )}
          </>
        )}
        <Table.Column verticalAlign="middle" width={50}>
          <Table.HeaderCell className="headColumn"></Table.HeaderCell>
          <ActionCell
            actions={[
              { label: 'Edit', key: 'edit', function: onIconEdit },
              { label: 'Delete', key: 'delete', function: onIconDelete },
            ]}
          />
        </Table.Column>
      </Table>
      {withPagination && (
        <div className="d-flex align-items-center justify-content-between mt-3">
          <p className="totalResult">{totalResult} Result</p>
          <button
            disabled={lastPage <= 1}
            onClick={() => setSelectedPage((oldState) => oldState + 1)}
            className="arrowBtnPagination"
          >
            Show more
          </button>
        </div>
      )}
      <ModalConfirm
        show={selectedDeleteId}
        onHide={() => setSelectedDeleteId(null)}
        text="Are you sure you want to delete this message?"
        textButton="Delete"
        handleClickButton={confirmDelete}
      />
    </div>
  )
}

export default Pipeline
