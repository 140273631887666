import { Controller } from 'react-hook-form'

import { SETTINGS_MESSAGE_TAG_PICKER } from '../../../../../utils/constants'
import CustomTag from '../../../../../components/CustomTag'
import CustomDropdownPicker from '../../../../../components/CustomDropdownPicker'

const ControllerTags = ({ name, control }) => {
  const deleteMessageTag = ({ tagInfo, onChange, value }) => {
    const newMessageTags = value.filter((id) => id !== tagInfo.id)
    onChange(newMessageTags)
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: false }}
      defaultValue={[]}
      render={({ field: { onChange, value } }) => (
        <CustomDropdownPicker
          value={value}
          onChange={onChange}
          routeParams={{ withPagination: false }}
          renderValue={(_, items) =>
            items?.map((tagInfo, index) => (
              <CustomTag
                key={index}
                tagInfo={tagInfo}
                deleteTag={() => deleteMessageTag({ tagInfo, onChange, value })}
              />
            ))
          }
          {...SETTINGS_MESSAGE_TAG_PICKER}
        />
      )}
    />
  )
}

export default ControllerTags
