import { DELIVERY_METHODS } from '../../utils/constants'
import { sortWeekdaysBySelected } from '../../utils/functions/deliveryConfigs'
import DeliveryDayCard from '../DeliveryDayCard'

const ConfigDeliveryTimePicker = ({ deliveryMethod, value, onChange, configDeliveryDayCard }) => {
  const handleTimeChange = (label, deliveryTime) => {
    const updatedValue = value.map((item) =>
      item.weekday === label ? { ...item, deliveryTime } : item
    )
    onChange(updatedValue)
  }
  const handleClickCheckBox = (label, isSelected) => {
    const updatedValue = value.map((item) =>
      item.weekday === label ? { ...item, isSelected } : item
    )

    onChange(sortWeekdaysBySelected(updatedValue))
  }

  return (
    <div className="listInfoDelivery">
      {value?.map(({ weekday, deliveryTime, isSelected }) => (
        <DeliveryDayCard
          key={weekday}
          label={weekday}
          deliveryTime={deliveryTime}
          isSelected={isSelected}
          onTimeChange={handleTimeChange}
          onSelectChange={handleClickCheckBox}
          countSelectedValues={value?.filter(({ isSelected }) => isSelected).length}
          showCheckBox={deliveryMethod === DELIVERY_METHODS.CUSTOM}
          {...configDeliveryDayCard}
        />
      ))}
    </div>
  )
}

export default ConfigDeliveryTimePicker
