import { LoadingIcon } from './SvgIcon'

const Spinner = ({ show }) => {
  if (!show) {
    return null
  }

  return (
    <div className="innerLoading">
      <LoadingIcon className="loading" />
    </div>
  )
}

export default Spinner
