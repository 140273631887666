import { useCallback } from 'react'
import { isEmpty, isNil } from 'lodash'

import { DAYS_OF_WEEK } from '../../utils/constants'

import './styles.css'

const WeekdayPicker = ({
  label,
  error,
  value = [],
  showTopDescription,
  onChange = () => {},
  labelClass = '',
  descriptionClass = '',
  description = '',
  innerClassName = '',
}) => {
  const onChangeSelectedWeekDays = useCallback(
    (newValue) => {
      const isSelectedDay = value.includes(newValue)
      const updatedValue = isSelectedDay
        ? value.filter((weekDay) => weekDay !== newValue)
        : [...value, newValue]

      onChange(updatedValue)
    },
    [value, onChange]
  )

  return (
    <div className={innerClassName}>
      {!isEmpty(label) && <label className={`inputLabel ${labelClass}`}>{label}</label>}
      {showTopDescription && (
        <p className={`inputBottomDescription ${descriptionClass}`}>{description}</p>
      )}
      <div className="innerWeekDaysBtn">
        {DAYS_OF_WEEK.map((weekdayInfo) => (
          <button
            type="button"
            key={weekdayInfo.value}
            onClick={() => onChangeSelectedWeekDays(weekdayInfo.value)}
            className={`weekdayBtn 
                ${value.includes(weekdayInfo.value) ? 'active' : ''} 
                ${!isNil(error) ? 'error' : ''}
            `}
          >
            {weekdayInfo.shortLabel}
          </button>
        ))}
      </div>
    </div>
  )
}

export default WeekdayPicker
