import { every, find, isNil } from 'lodash'
import { DAYS_OF_WEEK, DELIVERY_METHODS, WORK_DAYS } from '../constants'

export const configDeliveryConfigs = (method, enteredConfigs) => {
  const selectedConfigs = enteredConfigs.filter(({ isSelected }) => isSelected)

  switch (method) {
    // Handle WEEKDAY method by mapping WORK_DAYS to delivery configs
    case DELIVERY_METHODS.WEEKDAY:
      return {
        configs: WORK_DAYS.map(({ label }) => ({
          weekday: label,
          deliveryTime: enteredConfigs[0]?.deliveryTime,
        })),
        method: DELIVERY_METHODS.WEEKLY,
      }

    // Handle DAILY method by mapping selected configs to delivery times
    case DELIVERY_METHODS.DAILY:
      return {
        configs: selectedConfigs.map(({ deliveryTime }) => ({ deliveryTime })),
        method: DELIVERY_METHODS.DAILY,
      }

    // Default case returns filtered configs and original method
    default:
      return {
        configs: selectedConfigs,
        method: DELIVERY_METHODS.WEEKLY,
      }
  }
}

export const sortWeekdaysBySelected = (weekDaysList) => {
  const daysOfWeekOrder = DAYS_OF_WEEK.map(({ label }) => label)

  return weekDaysList.sort((a, b) => {
    if (a.isSelected === b.isSelected) {
      return daysOfWeekOrder.indexOf(a.weekday) - daysOfWeekOrder.indexOf(b.weekday)
    }
    return a.isSelected ? -1 : 1
  })
}

export const determineDeliveryMethod = (currentMethod, savedConfigs) => {
  const formattedConfigs = savedConfigs?.map((config) => ({
    deliveryTime: '21:00',
    isSelected: true,
    ...config,
  }))

  const workDayLabels = WORK_DAYS.map(({ label }) => label?.toLowerCase())
  const isWorkdaysOnly = every(formattedConfigs, ({ weekday }) =>
    workDayLabels.includes(weekday?.toLowerCase())
  )

  switch (true) {
    case isWorkdaysOnly:
      return {
        method: DELIVERY_METHODS.WEEKDAY,
        configs: [
          {
            weekday: 'Every weekday',
            deliveryTime: savedConfigs?.[0].deliveryTime || '21:00',
            isSelected: true,
          },
        ],
      }

    case currentMethod === DELIVERY_METHODS.DAILY:
      return {
        method: currentMethod,
        configs: [{ deliveryTime: '21:00', ...formattedConfigs?.[0], weekday: 'Every day' }],
      }

    default: {
      const customConfig = DAYS_OF_WEEK.map(({ label }) => {
        const selectedWeekday = find(formattedConfigs, { weekday: label?.toLowerCase() })
        return {
          deliveryTime: '21:00',
          ...selectedWeekday,
          weekday: label,
          isSelected: !isNil(selectedWeekday),
        }
      })

      return { method: DELIVERY_METHODS.CUSTOM, configs: sortWeekdaysBySelected(customConfig) }
    }
  }
}
