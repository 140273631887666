import { useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { isEmpty, isNil } from 'lodash'
import moment from 'moment-timezone'
import { Modal } from 'react-bootstrap'
import { Table } from 'rsuite'

import { TIME_FORMAT_12H } from '../../../../../utils/constants'
import { importUsers, importUsersPreview } from '../../../../../api/admin/themeSettings'
import { formatPhoneNumber } from '../../../../../utils/helpers'
import Button from '../../../../../components/Button'
import PrimaryButton from '../../../../../components/PrimaryButton'

const ModalPreviewNewUsers = ({ themeId, previewUserFile, updatePreviewFile }) => {
  const [showModal, setShowModal] = useState(false)

  const formatPreviewData = (usersPreview) => {
    return usersPreview.data.reduce((acc, { user, willSubscriptionBeCreated, subscription }) => {
      if (!willSubscriptionBeCreated) return acc

      const fullName = `${user?.firstName || ''} ${user?.lastName || ''}`
      return [
        ...acc,
        {
          fullName,
          timezone: user.timezoneName,
          phone: formatPhoneNumber(user.phone),
          deliveryTime: moment(subscription?.deliveryTime, 'HH:mm').format(TIME_FORMAT_12H),
        },
      ]
    }, [])
  }

  const { data: usersPreviewData, isLoading: isLoadingPreviewUsers } = useQuery({
    queryKey: ['previewImportUsers', themeId, previewUserFile?.lastModifiedDate],
    enabled: !isNil(previewUserFile),
    retryOnMount: false,
    retry: false,
    queryFn: () =>
      importUsersPreview({ themeId, users: previewUserFile }).then((data) => {
        setShowModal(true)
        return data
      }),
    // .then((data) => {
    //   setShowModal(true)
    //   return formatPreviewData(data)
    // })
    // .catch(() => setShowModal(false)),
    select: formatPreviewData,
  })

  const mutationImportNewUsers = useMutation({
    mutationKey: ['importUsers', themeId, previewUserFile?.lastModifiedDate],
    mutationFn: () => importUsers({ themeId, users: previewUserFile }),
    onSuccess: () => setShowModal(false),
  })

  return (
    <Modal
      size="lg"
      show={showModal}
      onExited={() => updatePreviewFile(null)}
      onHide={() => setShowModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>New Users</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table
          loading={isLoadingPreviewUsers}
          autoHeight
          wordWrap="break-word"
          data={usersPreviewData}
          id="table-top-contents"
        >
          <Table.Column verticalAlign="middle" flexGrow={1} align="left">
            <Table.HeaderCell className="headColumn">Full name</Table.HeaderCell>
            <Table.Cell dataKey="fullName" />
          </Table.Column>
          <Table.Column verticalAlign="middle" width={170}>
            <Table.HeaderCell className="headColumn">Phone</Table.HeaderCell>
            <Table.Cell dataKey="phone" />
          </Table.Column>
          <Table.Column align="left" verticalAlign="middle" width={230}>
            <Table.HeaderCell className="headColumn">Timezone</Table.HeaderCell>
            <Table.Cell dataKey="timezone" />
          </Table.Column>
          <Table.Column verticalAlign="middle" width={130}>
            <Table.HeaderCell className="headColumn">Delivery Time</Table.HeaderCell>
            <Table.Cell dataKey="deliveryTime" />
          </Table.Column>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end gap-3">
          <Button disable={mutationImportNewUsers.isPending} onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <PrimaryButton
            text="Confirm import"
            onClick={mutationImportNewUsers.mutate}
            disabled={mutationImportNewUsers.isPending || isEmpty(usersPreviewData)}
          />
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalPreviewNewUsers
