import { useMemo } from 'react'

import {
  DELIVERY_METHODS,
  DELIVERY_METHODS_INITIAL_INFO,
  INPUT_TYPES,
} from '../../../utils/constants'
import ControllerField from '../../../components/HookForm/components/ControllerField'

const DeliveryPreferences = ({ control, currentStep, trackDeliveryMethod, updateFormValue }) => {
  if (currentStep < 3) {
    return null
  }

  const { listDays } = useMemo(
    () => DELIVERY_METHODS_INITIAL_INFO.find(({ value }) => value === trackDeliveryMethod),
    []
  )
  const defaultDeliveryTimes = useMemo(
    () =>
      listDays.map(({ label }) => ({
        weekday: label,
        deliveryTime: '21:00',
        isSelected: true,
      })),
    []
  )

  return (
    <div className={`innerDeliveryPreferences ${currentStep !== 3 ? 'd-none' : ''}`}>
      <ControllerField
        control={control}
        type={INPUT_TYPES.INPUT_SELECT_TIMEZONE}
        name="timezone"
        rules={{ required: true }}
        title="Select your timezone"
        label="Timezone"
        customDropdownMenu="w-100"
        innerDropdown="innerTimezoneDropdown"
      />
      <div className="innerDeliverySettings">
        <ControllerField
          control={control}
          type={INPUT_TYPES.INPUT_DROPDOWN_DELIVERY_METHOD}
          name="deliveryMethod"
          defaultValue={DELIVERY_METHODS.DAILY}
          rules={{ required: true }}
          data={DELIVERY_METHODS_INITIAL_INFO}
          title="Set meditation time"
          label="Meditation time"
          updateFormValue={updateFormValue}
          customDropdownMenu="w-100"
          innerDropdown="mw-100"
          valueKey="value"
        />
        <ControllerField
          name="configs"
          control={control}
          rules={{ required: true }}
          deliveryMethod={trackDeliveryMethod}
          defaultValue={defaultDeliveryTimes}
          type={INPUT_TYPES.INPUT_CONFIG_DELIVERY_TIME_PICKER}
        />
      </div>
    </div>
  )
}

export default DeliveryPreferences
