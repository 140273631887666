import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Toggle, Tooltip, Whisper } from 'rsuite'

import { updateTag } from '../../../../api/admin/tags'

const ToggleTagVisibleUser = ({ id, isVisibleToUser, updateQueryKey, isLoading }) => {
  const queryClient = useQueryClient()

  const mutateUpdateTagUserVisibleStatus = useMutation({
    mutationKey: ['updateTagInfo', id],
    mutationFn: updateTag,
    onSuccess: (_, { tagId, isVisibleToUser }) => {
      queryClient.setQueryData(updateQueryKey, (oldInfo) => {
        const newData = oldInfo?.data.data?.map((tagInfo) =>
          tagId === tagInfo.id ? { ...tagInfo, isVisibleToUser } : tagInfo
        )
        return { data: { ...oldInfo.data, data: newData } }
      })
    },
  })

  return (
    <Whisper
      placement="top"
      trigger="hover"
      speaker={
        <Tooltip>
          <p>{isVisibleToUser ? 'Visible for users' : 'Invisible for users'}</p>
        </Tooltip>
      }
    >
      <div>
        <Toggle
          size="sm"
          checked={isVisibleToUser}
          className="toggleStatus"
          onChange={() =>
            mutateUpdateTagUserVisibleStatus.mutate({
              tagId: id,
              isVisibleToUser: !isVisibleToUser,
            })
          }
          loading={isLoading || mutateUpdateTagUserVisibleStatus.isPending}
        />
      </div>
    </Whisper>
  )
}

export default ToggleTagVisibleUser
