import { useMemo, useState } from 'react'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import moment from 'moment-timezone'
import { isEmpty } from 'lodash'

import {
  CHART_TYPE_DELIVERED_MESSAGES,
  BUTTONS_DASHBOARD_TYPE_CHARTS,
  DATE_FORMAT_MDY,
  CHART_TYPE_USERS,
} from '../../utils/constants'
import { getDeliveredMessagesChart, getUsersChart } from '../../api/dashboard'
import useChart from '../../utils/hooks/useChart'
import { LoadingIcon } from '../SvgIcon'
import ChartProgression from './ChartProgression'

import './styles.css'

const LineChart = ({ filterData }) => {
  const [visibleChartType, setVisibleChartType] = useState(CHART_TYPE_DELIVERED_MESSAGES)
  const { formatDataLineChart } = useChart()

  const requestParams = useMemo(
    () => ({
      dateCollectionRange: [
        moment().subtract(1, 'w').format(DATE_FORMAT_MDY),
        moment().format(DATE_FORMAT_MDY),
      ],
      ...filterData,
    }),
    [filterData]
  )

  const {
    data,
    isFetching: isFetchingMessages,
    isLoading: isLoadingMessages,
  } = useQuery({
    queryKey: ['dashboardChart', visibleChartType, requestParams],
    queryFn: () => getDeliveredMessagesChart(requestParams),
    select: ({ data }) => {
      return formatDataLineChart({
        data,
        dateRange: requestParams?.dateCollectionRange,
      })
    },
    placeholderData: keepPreviousData,
    enabled: visibleChartType === CHART_TYPE_DELIVERED_MESSAGES,
  })

  const {
    data: usersChartData,
    isLoading: isLoadingUsers,
    isFetching: isFetchingUsers,
  } = useQuery({
    queryKey: ['dashboardChart', visibleChartType, filterData],
    queryFn: () => getUsersChart(requestParams),
    select: ({ data }) => {
      return formatDataLineChart({
        data,
        dateRange: requestParams?.dateCollectionRange,
      })
    },
    enabled: visibleChartType === CHART_TYPE_USERS,
    placeholderData: keepPreviousData,
  })

  const visibleData = useMemo(
    () => (visibleChartType === CHART_TYPE_DELIVERED_MESSAGES ? data : usersChartData),
    [visibleChartType, data, usersChartData]
  )

  return (
    <div>
      <div className="d-flex align-items-center ms-1">
        {BUTTONS_DASHBOARD_TYPE_CHARTS.map(({ title, chartType }) => {
          const isActiveTab = chartType === visibleChartType

          return (
            <button
              key={chartType}
              className={`tab ${isActiveTab ? 'active' : ''}`}
              onClick={() => setVisibleChartType(chartType)}
            >
              {title}
            </button>
          )
        })}
      </div>
      <div className="position-relative innerDashboardCharts">
        {isFetchingMessages ||
        isLoadingMessages ||
        isFetchingUsers ||
        isLoadingUsers ||
        !isEmpty(visibleData) ? (
          <ChartProgression data={visibleData} />
        ) : (
          <div className="innerLoaderDashboardChart">
            <p>No data found!</p>
          </div>
        )}
        {isFetchingMessages || isLoadingMessages || isFetchingUsers || isLoadingUsers ? (
          <div className="innerLoaderDashboardChart">
            <LoadingIcon className="dashboardChartLoading" width={40} height={40} />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default LineChart
