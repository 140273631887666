import { useEffect, useState } from 'react'
import { find, isEmpty, isNil } from 'lodash'
import { Tooltip, Whisper } from 'rsuite'
import { Dropdown } from 'react-bootstrap'

import { Info } from '../SvgIcon'
import CustomToggle from '../CustomToggle'
import DropdownInput from '../DropdownInput'

import './styles.css'

const CustomDropdown = ({
  id,
  data = [],
  value,
  error,
  onChange,
  label = '',
  title = 'Month',
  disabled = false,
  titleClass = '',
  innerDropdown = '',
  titleKey = 'title',
  valueKey = '',
  customDropdownMenu = '',
  showIconInfo = false,
  infoText = '',
  getDropdownData,
}) => {
  const [dropdownData, setDropdownData] = useState(data)
  const [titleValue, setTitleValue] = useState(title)

  // Update titleValue when value, dropdownData, or data changes
  useEffect(() => {
    const selectedItem = valueKey ? find(dropdownData, { [valueKey]: value }) : value
    setTitleValue(selectedItem?.[titleKey] || selectedItem || title)
  }, [value, dropdownData, data, valueKey, titleKey, title])

  // Fetch dropdown data if getDropdownData is provided
  useEffect(() => {
    if (!isNil(getDropdownData)) {
      getDropdownData().then(({ data }) => setDropdownData(data))
    }
  }, [getDropdownData])

  // Update dropdownData when data prop changes
  useEffect(() => {
    if (!isEmpty(data)) {
      setDropdownData(data)
    }
  }, [data])

  return (
    <div className={`innerDropdown ${innerDropdown}`}>
      {!isEmpty(label) && (
        <div className="d-flex align-items-center gap-1 inputLabel">
          <label htmlFor={id}>{label}</label>
          {showIconInfo && (
            <Whisper
              placement="top"
              trigger="hover"
              speaker={
                <Tooltip>
                  <p>{infoText}</p>
                </Tooltip>
              }
            >
              <span className="d-flex">
                <Info fill="var(--darkest-gray)" size={15} />
              </span>
            </Whisper>
          )}
        </div>
      )}
      <Dropdown id={id}>
        <Dropdown.Toggle disabled={disabled} as={CustomToggle}>
          <DropdownInput
            error={error}
            title={titleValue}
            disabled={disabled}
            titleClass={titleClass}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu className={`customDropdownMenu ${customDropdownMenu}`}>
          {dropdownData?.map(({ disabled: itemDisabled = false, ...item }) => (
            <Dropdown.Item
              key={item?.[titleKey]}
              className="itemMenu"
              onClick={() => onChange(valueKey ? item[valueKey] : item)}
              disabled={itemDisabled}
            >
              {item?.[titleKey]}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default CustomDropdown
