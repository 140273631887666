import moment from 'moment-timezone'

const useTime = () => {
  const getTime = (value) => {
    const dateTime = moment(value.toDate())
    const time = dateTime.format('HH:mm')
    const timeZone = dateTime.utcOffset() / 60
    return { time, timeZone }
  }

  const getMessageTime = (message) => {
    const { deliveryDate, deliveryTime } = message
    return moment(`${deliveryDate}T${deliveryTime}`)
  }

  const months = moment.months().map((title, number) => ({ title, number }))

  const weekdayShort = moment.weekdaysShort()
  const getWeekDays = (month, year) => {
    const endDate = moment.utc().year(year).month(month).endOf('month')
    const daysInMonth = Array.from({ length: endDate.date() }, (_, i) =>
      moment
        .utc()
        .year(year)
        .month(month)
        .date(i + 1)
    )

    const uniqueWeeks = daysInMonth
      .map((day) => ({ day, week: day.week() }))
      .filter(({ week }, i, arr) => arr.findIndex((info) => info.week === week) === i)

    return uniqueWeeks.map(({ week, day }) => ({
      week,
      days: Array.from({ length: 7 }, (_, i) =>
        moment.utc(day).week(week).startOf('week').add(i, 'day')
      ),
    }))
  }

  const getGreeting = () => {
    const currentHour = moment().format('HH')
    if (currentHour >= 5 && currentHour < 12) {
      return 'Good morning'
    } else if (currentHour >= 12 && currentHour < 18) {
      return 'Good afternoon'
    } else if (currentHour >= 18 && currentHour < 21) {
      return 'Good evening'
    } else {
      return 'Good night'
    }
  }

  return {
    months,
    getTime,
    getWeekDays,
    weekdayShort,
    getMessageTime,
    greeting: getGreeting(),
  }
}

export default useTime
