import { useDispatch } from 'react-redux'

import { APP_ELEMENTS } from '../../../../utils/constants'
import { setShowModalSupport } from '../../../../redux/actions/ui'
import { Facebook, Instagram } from '../../../SvgIcon'

import styles from './UserAccountFooter.module.css'

const UserAccountFooter = () => {
  const dispatch = useDispatch()

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className="d-flex align-items-center gap-3 justify-content-between pb-5 mb-5  border-bottom border-dark">
          <img src={APP_ELEMENTS.DARK_LOGO} alt="Logo" className={styles.footerLogo} />
          <div className="d-flex align-items-center gap-3">
            <a href="https://www.facebook.com/people/Daydrop/61551865300032">
              <Facebook fill="var(--swatch--cta-blue)" size={20} />
            </a>
            <a href="https://www.instagram.com/daydrop_wellness">
              <Instagram fill="var(--swatch--cta-blue)" size={20} />
            </a>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center gap-4 flex-column flex-md-column flex-lg-row">
          <p className={styles.copyRightText}>© 2024 {APP_ELEMENTS.NAME}. All rights reserved.</p>
          <a href="https://daydrop.me/terms" className={styles.footerLink}>
            Terms of Services
          </a>
          <a href="https://daydrop.me/privacy" className={styles.footerLink}>
            Privacy Policy
          </a>
          <p onClick={() => dispatch(setShowModalSupport(true))} className={styles.footerLink}>
            Contact Us
          </p>
        </div>
      </div>
    </footer>
  )
}

export default UserAccountFooter
