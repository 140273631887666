import { TimeFilled } from '../SvgIcon'
import CustomCheckbox from '../CustomCheckbox'
import CustomTimePicker from '../CustomTimePicker'

import './styles.css'

const DeliveryDayCard = ({
  label,
  deliveryTime,
  onTimeChange,
  isSelected,
  onSelectChange,
  showCheckBox,
  countSelectedValues,
  fillClockIcon = 'var(--swatch--cta-blue)',
}) => {
  return (
    <div className={`cardDeliveryWeekDay ${!isSelected ? 'unselected' : ''}`}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-2">
          {showCheckBox && (
            <CustomCheckbox
              value={isSelected}
              disabled={countSelectedValues === 1 && isSelected}
              onChange={() => onSelectChange(label, !isSelected)}
            />
          )}
          <button
            type="button"
            disabled={countSelectedValues === 1 && isSelected}
            onClick={() => {
              if (showCheckBox) {
                onSelectChange(label, !isSelected)
              }
            }}
            className="weekDayName"
          >
            {label}
          </button>
        </div>
        <CustomTimePicker
          value={deliveryTime}
          onChange={(time) => onTimeChange(label, time)}
          disabled={!isSelected}
          customClassName="deliveryTime"
          inputIcon={
            <div className="innerIconClock">
              <TimeFilled size={20} fill={fillClockIcon} />
            </div>
          }
        />
      </div>
    </div>
  )
}

export default DeliveryDayCard
