import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { TagPicker } from 'rsuite'
import { isEmpty, some } from 'lodash'
import randomColor from 'randomcolor'

import { BUTTON_KEY_BACKSPACE, SETTINGS_RANDOM_COLOR } from '../../../../../utils/constants'
import { createTag, getAllTags } from '../../../../../api/admin/tags'
import {
  changeThemeTags,
  deleteThemeTag,
  getThemeTags,
} from '../../../../../api/admin/themeSettings'
import CustomTag from '../../../../../components/CustomTag'

import './styles.css'

const ThemeTags = () => {
  const [allTags, setAllTags] = useState([])
  const [themeTags, setThemeTags] = useState([])
  const { themeId } = useParams()

  const createThemeTag = (values, name) => {
    const color = randomColor(SETTINGS_RANDOM_COLOR)

    createTag({ name, color }).then(({ data }) => {
      setAllTags((oldState) => [...oldState, data])
      const newThemeTags = [...themeTags, data.id]
      return changeThemeTags({ id: themeId, tagsIds: newThemeTags }).then(() =>
        setThemeTags(newThemeTags)
      )
    })
  }

  const addNewThemeTag = (tags) => {
    return changeThemeTags({ id: themeId, tagsIds: tags }).then(() => setThemeTags(tags))
  }

  const deleteThemeTagById = (tagId) => {
    return deleteThemeTag(themeId, tagId).then(() =>
      setThemeTags((oldState) => oldState.filter((selectedTagId) => selectedTagId !== tagId))
    )
  }

  const updateThemeTags = (newDataTags, item) => {
    const newTag = newDataTags[newDataTags.length - 1]
    const isChangedTags =
      themeTags.includes(newTag) ||
      some(allTags, { id: newTag }) ||
      item?.key === BUTTON_KEY_BACKSPACE ||
      isEmpty(newDataTags)

    return isChangedTags ? addNewThemeTag(newDataTags) : createThemeTag(newDataTags, newTag)
  }

  useEffect(() => {
    getAllTags({ withPagination: false }).then(({ data }) => {
      setAllTags(data)
    })
  }, [])

  useEffect(() => {
    getThemeTags(themeId).then(({ data }) => {
      const themeTags = data.map(({ id }) => id)
      setThemeTags(themeTags)
    })
  }, [])

  return (
    <section className="mt-3">
      <p className="titleThemeTagSelector">Theme tags</p>
      <TagPicker
        valueKey="id"
        creatable
        labelKey="name"
        className="themeTags"
        cleanable={false}
        placeholder="Choose tags for theme"
        data={allTags}
        value={themeTags}
        onChange={updateThemeTags}
        renderValue={(_, items) =>
          items?.map((tagInfo, index) => (
            <CustomTag
              key={index}
              deleteTag={() => deleteThemeTagById(tagInfo.id)}
              tagInfo={tagInfo}
            />
          ))
        }
      />
    </section>
  )
}

export default ThemeTags
