import { toast } from 'react-toastify'

import {
  DISCOUNT_TYPES,
  COUPON_DURATION,
  ALL_ACTIVE_SUBSCRIPTION_STATUSES,
  ALL_NON_RENEW_SUBSCRIPTION_STATUSES,
  SUBSCRIPTION_STATUSES,
  PAYMENT_OPTIONS,
  SUBSCRIPTABLE_TYPES,
} from '../constants'
import { applyCoupon } from '../../api/stripe'

export const formatDiscountLabel = ({ discountType, discountAmount }) => {
  return discountType === DISCOUNT_TYPES.AMOUNT ? `$${discountAmount}` : `${discountAmount}%`
}

const getCouponDurationText = ({
  couponDuration,
  defaultPrice,
  discountedPrice,
  discount,
  trialText,
  priceTypeLabel,
  priceType,
  discountMonthDuration,
  themeName,
}) => {
  const generateSubscriptionText = () => {
    switch (couponDuration) {
      case COUPON_DURATION.ONCE:
        return `for the first year, $${defaultPrice} ${PAYMENT_OPTIONS[priceType]?.paymentFrequencyKeys}`
      case COUPON_DURATION.REPEATING:
        return `for ${Math.ceil(discountMonthDuration / 12)} years, $${defaultPrice} ${
          PAYMENT_OPTIONS[priceType]?.paymentFrequencyKeys
        }`
      default:
        return PAYMENT_OPTIONS[priceType]?.paymentFrequencyKeys
    }
  }

  switch (priceType) {
    case SUBSCRIPTABLE_TYPES.STRIPE_SUBSCRIPTION:
      return (
        <>
          <span>
            <s>${defaultPrice}</s> <b>${discountedPrice}</b> {generateSubscriptionText()}
          </span>
          <span>
            <b>{discount}</b> discount applied
          </span>
          {discountedPrice ? trialText : null} {priceTypeLabel} <span>Cancel anytime</span>
        </>
      )
    case SUBSCRIPTABLE_TYPES.PAID_CONTENTS_SUBSCRIPTION:
      return (
        <>
          <span>
            <s>${defaultPrice}</s> <b>${discountedPrice}</b> for {themeName}
          </span>
          <span>
            <b>{discount}</b> discount applied
          </span>
          {discountedPrice ? trialText : null}
        </>
      )
    default:
      return null
  }
}

export const generateSubscriptionInfoText = (props) => {
  const {
    defaultPrice,
    discountType,
    discountAmount,
    discountedPrice,
    trial,
    priceType,
    themeName,
  } = props

  const discount = formatDiscountLabel({ discountType, discountAmount })
  const trialText =
    trial && priceType === SUBSCRIPTABLE_TYPES.STRIPE_SUBSCRIPTION ? (
      <span>{trial}-day free trial</span>
    ) : null
  const priceText =
    priceType === SUBSCRIPTABLE_TYPES.STRIPE_SUBSCRIPTION ? (
      <span>
        <b>${discountedPrice}</b> {PAYMENT_OPTIONS[priceType]?.paymentFrequencyKeys}
      </span>
    ) : (
      <span>
        <b>${discountedPrice}</b> for <b>{themeName}</b>
      </span>
    )
  const priceTypeLabel =
    priceType === SUBSCRIPTABLE_TYPES.STRIPE_SUBSCRIPTION ? (
      <span>{PAYMENT_OPTIONS[priceType]?.label}</span>
    ) : null

  if (discountedPrice !== defaultPrice)
    return getCouponDurationText({
      ...props,
      discount,
      trialText,
      priceTypeLabel,
    })

  return (
    <>
      {priceText} {trialText} {priceTypeLabel}
      {priceType === SUBSCRIPTABLE_TYPES.STRIPE_SUBSCRIPTION ? <span>Cancel anytime</span> : ''}
    </>
  )
}

export const handleCouponApplication = async (
  { couponValue, themeId, trialPeriod, themeName },
  updateSubscriptionTextInfo
) => {
  const toastId = toast.loading('Loading...')
  try {
    const { data } = await applyCoupon({ promotionCode: couponValue, themeId })
    const newSubscriptionTextInfo = generateSubscriptionInfoText({
      ...data?.price,
      themeName,
      trial: trialPeriod,
      defaultPrice: data?.price?.old,
      discountedPrice: data?.price?.new,
      couponDuration: data?.price?.duration,
      discountMonthDuration: data?.price?.durationInMonths,
    })
    updateSubscriptionTextInfo(newSubscriptionTextInfo)
    toast.update(toastId, {
      render: (
        <>
          Coupon <b>{couponValue}</b> applied successfully!
        </>
      ),
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
    })

    return data?.price
  } catch (error) {
    toast.update(toastId, {
      render: (
        <>
          Coupon <b>{couponValue}</b> is invalid.
        </>
      ),
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
    })
    return null
  }
}

export const hasUserActiveThemeSubscription = (status) => {
  return [...ALL_ACTIVE_SUBSCRIPTION_STATUSES, ...ALL_NON_RENEW_SUBSCRIPTION_STATUSES].includes(
    status
  )
}

export const shouldSubscribe = (status) => {
  return [SUBSCRIPTION_STATUSES.ABANDONED_CART, SUBSCRIPTION_STATUSES.CANCELED].includes(status)
}
