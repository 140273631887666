import { useQuery } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { Link } from 'react-router-dom'

import { APP_ELEMENTS } from '../../../../utils/constants'
import { routes } from '../../../../router/routes'
import { getUserSubscription } from '../../../../api/user'
import { LoadingIcon } from '../../../../components/SvgIcon'
import SubscriptionCard from '../../../../components/SubscriptionCard'
import EmptyBlock from '../../../../components/EmptyBlock'

const SubscriptionList = () => {
  const { data: userSubscriptions, isLoading } = useQuery({
    queryKey: ['userSubscriptions'],
    queryFn: () =>
      getUserSubscription({ size: 5, page: 1, orderBy: ['status'], orderDirection: 'asc' }),
  })

  return (
    <div className="innerHomeThemes">
      <p className="titleProfileFourth mb-2">
        <b>My themes</b>
      </p>
      <div className="position-relative flex-grow-1">
        {userSubscriptions?.data?.data?.map((subscription) => (
          <SubscriptionCard
            key={subscription.themeId}
            {...subscription}
            innerCardClassName="sm"
            price={subscription.newPrice}
            name={subscription.theme?.name}
            themeId={subscription.theme?.id}
            imgSrc={`data:image/png;base64, ${subscription.theme.contact.image}`}
          />
        ))}
        {isLoading && (
          <div className="innerLoader">
            <LoadingIcon size={40} className="loaderIcon" />
          </div>
        )}
        {isEmpty(userSubscriptions?.data?.data) && !isLoading ? (
          <EmptyBlock
            innerClassName="mt-3 ms-auto me-auto innerEmptySubscriptionList gap-sm-0"
            title="You don't have subscriptions"
            imageSrc={APP_ELEMENTS.SMALL_LOGO}
          />
        ) : null}
        {userSubscriptions?.data?.total > 5 && (
          <Link className="viewMore" to={routes.userThemes}>
            View/manage programs
          </Link>
        )}
      </div>
    </div>
  )
}

export default SubscriptionList
