import { isNil } from 'lodash'

import Calendar from '../../../../components/Calendar'
import Providers from './Providers'

const CalendarBlock = ({ isActiveBlock, themeId, providersDetails }) => {
  return (
    <section className={`calendarBlock tabBlock ${isActiveBlock ? 'visibleTabBlock' : ''}`}>
      <div className="blockThemeCalendar">
        <Providers themeId={themeId} selectedProviders={providersDetails} />
        <Calendar
          enableGetData={!isNil(themeId) && isActiveBlock}
          isAdmin={false}
          themeId={themeId}
        />
      </div>
    </section>
  )
}

export default CalendarBlock
