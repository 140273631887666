import { useCallback, useEffect, useState } from 'react'
import { createSearchParams, useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import moment from 'moment-timezone'
import { toNumber } from 'lodash'
import { Tooltip, Uploader, Whisper } from 'rsuite'

import { HISTORY_BACK, INPUT_TYPES, PRICE_TYPES } from '../../../utils/constants'
import { routes } from '../../../router/routes'
import { getThemeDetails, updateThemeInfo } from '../../../api/admin/themes'
import { getSampleImportUsersFile } from '../../../api/admin/themeSettings'
import { getThemeContent } from '../../../api/admin/themeContent'
import {
  CloudUpload,
  LoadingIcon,
  Settings,
  Statistics,
  Contact,
  MessageAltDetail,
  Copy,
  Analytics,
  Info,
} from '../../../components/SvgIcon'
import ModalDuplicateTheme from '../../../components/Modals/ModalDuplicateTheme'
import ModalHardcodeMessage from '../../../components/Modals/ModalHardcodeMessage'
import ModalHubspotIntegration from '../../../components/Modals/ModalHubspotIntegration'
import ControllerField from '../../../components/HookForm/components/ControllerField'
import BackButton from '../../../components/BackButton'
import IconButton from '../../../components/IconButton'
import ModalContact from '../../../components/Modals/ModalContact'
import PrimaryButton from '../../../components/PrimaryButton'
import ThemeTags from './components/ThemeTags'
import ActivateTheme from './components/ActivateTheme'
import PricingModelInfo from './components/PricingModelInfo'
import ModalPreviewNewUsers from './components/ModalPreviewNewUsers'

import './styles.css'

const ThemeSettings = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [showModalDuplicate, setShowModalDuplicate] = useState(false)
  const [showModalContact, setShowModalContact] = useState(false)
  const [showHardcodeMessages, setShowHardcodeMessages] = useState(false)
  const [showModalHubspotIntegration, setShowModalHubspotIntegration] = useState(false)
  const [themeInfo, setThemeInfo] = useState({})
  const [uploadedUserFile, setUploadedUserFile] = useState(null)
  const navigation = useNavigate()
  const { themeId } = useParams()
  const { control, reset, handleSubmit, setValue, formState, watch, unregister, register } =
    useForm()

  const uploadUsers = useCallback(
    (filesArray) => {
      const userFile = filesArray[filesArray.length - 1].blobFile
      setUploadedUserFile(userFile)
    },
    [themeId]
  )

  const mutationSampleImportUserFile = useMutation({
    mutationKey: ['sampleImportUserFile'],
    mutationFn: getSampleImportUsersFile,
  })

  const { data: themeContents } = useQuery({
    queryKey: ['themeContent', themeId],
    queryFn: () => getThemeContent(themeId),
    select: ({ data }) => {
      return data.map(({ externalId, subtitle, ...rest }) => ({
        name: `${externalId} | ${subtitle}`,
        ...rest,
      }))
    },
  })

  const mutationUpdateTheme = useMutation({
    mutationKey: ['updateThemeInfo', themeId],
    mutationFn: (params) => {
      return updateThemeInfo({ themeId, ...params }, { success: 'Theme updated successfully' })
    },
    onSuccess: (_, newThemeData) => {
      reset(newThemeData)
      setThemeInfo((oldState) => ({
        ...oldState,
        activePrice: {
          ...oldState.activePrice,
          extensionDays: newThemeData.priceExtensionDays,
          type: newThemeData.priceType,
        },
      }))
    },
  })
  const navigateToThemeAnalytic = useCallback(() => {
    const searchParams = { filterData: JSON.stringify({ themeId: toNumber(themeId) }) }
    navigation({
      pathname: routes.dashboard,
      search: '?' + createSearchParams(searchParams).toString(),
    })
  }, [themeId])

  const updatePriceType = useCallback(({ priceType, extensionDays }) => {
    const action = priceType === PRICE_TYPES.RECURRING ? unregister : register

    setValue('priceType', priceType)
    action('priceExtensionDays', { value: extensionDays })
  }, [])

  useEffect(() => {
    setIsLoading(true)
    getThemeDetails(themeId)
      .then(({ data }) => {
        setThemeInfo(data)
        reset({
          deliveryTime: moment(data.deliveryTime, 'HH:mm').format('HH:mm'),
          adminDeliveryTime: moment(data.adminDeliveryTime, 'HH:mm').format('HH:mm'),
          priceType: data.activePrice.type,
          emergencyContentId: data.emergencyContentId,
        })
      })
      .finally(() => setIsLoading(false))
  }, [])

  return (
    <div>
      <PrimaryButton
        text="Update"
        disabled={!formState.isDirty || mutationUpdateTheme.isPending}
        customClass="buttonSaveNewThemeSettings"
        onClick={handleSubmit(mutationUpdateTheme.mutate)}
      />
      <div className="innerTopLeftButtons">
        <BackButton route={HISTORY_BACK} />
        <IconButton
          disabledTooltip={isLoading}
          buttonClassName="p-0 bg-transparent"
          renderTooltipContent={() => <p>Duplicate</p>}
          placement="bottomEnd"
          id="button-modal-duplicate"
          onClick={() => setShowModalDuplicate(true)}
        >
          <Copy size={23} fill="var(--catalina-blue)" />
        </IconButton>
        <IconButton
          disabledTooltip={isLoading}
          buttonClassName="p-0 bg-transparent"
          renderTooltipContent={() => <p>Contact</p>}
          placement="bottomEnd"
          id="button-modal-contact"
          onClick={() => setShowModalContact(true)}
        >
          <Contact size={25} fill="var(--catalina-blue)" />
        </IconButton>

        <IconButton
          disabledTooltip={isLoading}
          buttonClassName="p-0 bg-transparent"
          renderTooltipContent={() => <p>Table of Hardcoded messages</p>}
          placement="bottomEnd"
          id="buttom-modal-constant-msg"
          onClick={() => setShowHardcodeMessages(true)}
        >
          <MessageAltDetail size={28} fill="var(--catalina-blue)" />
        </IconButton>
        <IconButton
          disabledTooltip={isLoading}
          buttonClassName="p-0 bg-transparent"
          renderTooltipContent={() => <p>Statistics</p>}
          placement="bottomEnd"
          id="buttom-modal-constant-msg"
          onClick={navigateToThemeAnalytic}
        >
          <Analytics size={28} color="var(--catalina-blue)" />
        </IconButton>
      </div>

      <div className="settingsContainer">
        <section className="settingsBlock pt-2 pb-2">
          <div className="d-flex align-items-center gap-4 justify-content-between">
            <div className="d-flex align-items-center gap-3 flex-grow-1">
              <Settings className="iconSettings" size={45} />
              <h2 className="titleNameTheme">{themeInfo?.name}</h2>
            </div>
            <Statistics fill="var(--catalina-blue)" width={200} />
          </div>
          <div className="d-flex justify-content-end align-items-center gap-3 mt-3">
            <button
              className="themeIntegrationBtn"
              onClick={() => setShowModalHubspotIntegration(true)}
            >
              <img src="/images/hubspotIcon.webp" alt="Hubspot Integration" />
              <span>HubSpot Integration</span>
            </button>
          </div>
        </section>

        <section className="settingsBlock mt-3">
          <ActivateTheme {...themeInfo} updateThemeInfo={setThemeInfo} />
          <PricingModelInfo
            control={control}
            priceType={watch('priceType')}
            price={themeInfo?.activePrice?.price}
            updatePriceType={updatePriceType}
            updateFormValue={setValue}
            extensionDays={themeInfo?.activePrice?.extensionDays}
          />
        </section>
        <section className="settingsBlock mt-3">
          <div className="d-flex align-items-end gap-3 justify-content-between">
            <div>
              <p className="themeSettingLabels">Emergency content</p>
              <p className="descriptionThemeSettings">
                Specifies content that is sent when a user sends the command "favorite" and they
                don't have any content to select from
              </p>
            </div>
            <ControllerField
              name="emergencyContentId"
              type={INPUT_TYPES.INPUT_DROPDOWN}
              control={control}
              rules={{ required: false }}
              defaultValue=""
              title="Emergency content"
              titleKey="name"
              valueKey="id"
              innerDropdown="innerSettingsInput"
              data={themeContents}
            />
          </div>
        </section>
        <ThemeTags />
        <section>
          <div className="mt-5 d-flex align-items-center justify-content-between gap-2 mb-3">
            <div className="d-flex align-items-center gap-2">
              <p className="themeSettingLabels mb-0">Upload Users</p>
              <Whisper
                placement="top"
                trigger="hover"
                enterable
                speaker={
                  <Tooltip>
                    <p>
                      Upload an .XLSX file with user information to add them to the program. Users
                      will be subscribed to this theme with a free coupon{' '}
                      <span
                        onClick={mutationSampleImportUserFile.mutate}
                        className="cursorPointer highLightLink light"
                      >
                        Download sample XLSX
                      </span>
                      .
                    </p>
                  </Tooltip>
                }
              >
                <span className="d-flex">
                  <Info fill="var(--darkest-gray)" size={20} />
                </span>
              </Whisper>
            </div>
            <p
              onClick={mutationSampleImportUserFile.mutate}
              className="cursorPointer highLightLink"
            >
              Sample .xlsx file
            </p>
          </div>
          <Uploader
            onChange={uploadUsers}
            draggable
            action=""
            disabledFileItem
            accept=".xlsx, .xls"
            autoUpload={false}
            disabled={!themeInfo?.isPublished}
            className="position-relative cursorPointer"
            multiple={false}
          >
            <div
              style={{
                display: 'flex',
                gap: 10,
                alignItems: 'center',
                justifyContent: 'center',
                height: 200,
                backgroundColor: themeInfo?.isPublished ? 'transparent' : '#cbc8c830',
              }}
            >
              <CloudUpload size={35} style={{ marginRight: 10 }} />
              {themeInfo?.isPublished ? (
                <span>
                  Upload <b>Users</b> .xlsx file
                </span>
              ) : (
                <span>
                  For upload new users, please <b>publish</b> theme
                </span>
              )}
            </div>
          </Uploader>
        </section>
      </div>
      <ModalContact
        themeId={themeId}
        show={showModalContact}
        onHide={() => setShowModalContact(false)}
      />
      <ModalHardcodeMessage
        show={showHardcodeMessages}
        onHide={() => setShowHardcodeMessages(false)}
      />
      <ModalDuplicateTheme
        show={showModalDuplicate}
        onHide={() => setShowModalDuplicate(false)}
        {...themeInfo}
      />
      {isLoading && (
        <div className="loadingThemeSettings">
          <LoadingIcon />
        </div>
      )}
      <ModalHubspotIntegration
        themeId={themeId}
        show={showModalHubspotIntegration}
        onHide={() => setShowModalHubspotIntegration(false)}
      />
      <ModalPreviewNewUsers
        themeId={themeId}
        previewUserFile={uploadedUserFile}
        updatePreviewFile={setUploadedUserFile}
      />
    </div>
  )
}

export default ThemeSettings
