import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { isEmpty } from 'lodash'

import {
  DELIVERY_METHODS,
  DELIVERY_METHODS_INITIAL_INFO,
  INPUT_TYPES,
} from '../../../../utils/constants'
import {
  getThemeDeliveryConfigs,
  updateThemeDeliveryConfigsWithToast,
} from '../../../../api/user/theme'
import {
  configDeliveryConfigs,
  determineDeliveryMethod,
} from '../../../../utils/functions/deliveryConfigs'
import ControllerField from '../../../../components/HookForm/components/ControllerField'
import PrimaryButton from '../../../../components/PrimaryButton'

const SettingsBlock = ({ isActiveBlock, themeId }) => {
  const { handleSubmit, control, watch, formState, reset, setValue, getValues } = useForm({
    defaultValues: { deliveryMethod: DELIVERY_METHODS.DAILY, configs: [] },
  })

  const mutationUpdateThemeDeliveryConfigs = useMutation({
    mutationKey: ['updateThemeDeliveryConfigs'],
    mutationFn: (data) => {
      const { method, configs } = configDeliveryConfigs(data.deliveryMethod, data.configs)
      return updateThemeDeliveryConfigsWithToast({ type: method, configs, themeId })
    },
    onSuccess: (_, formData) => {
      reset(formData, { keepDirty: false })
    },
  })

  useEffect(() => {
    if (isEmpty(getValues('configs')) && isActiveBlock) {
      getThemeDeliveryConfigs(themeId).then(({ data }) => {
        const { method, configs } = determineDeliveryMethod(data.type, data.configs)
        reset({ deliveryMethod: method, configs }, { keepDirty: false })
      })
    }
  }, [isActiveBlock])

  return (
    <section className={`settingsBlock tabBlock ${isActiveBlock ? 'visibleTabBlock' : ''}`}>
      <form
        onSubmit={handleSubmit(mutationUpdateThemeDeliveryConfigs.mutate)}
        className="formDeliverySettings"
        id="delivery_settings"
      >
        <ControllerField
          control={control}
          type={INPUT_TYPES.INPUT_DROPDOWN_DELIVERY_METHOD}
          name="deliveryMethod"
          defaultValue={DELIVERY_METHODS.DAILY}
          rules={{ required: false }}
          data={DELIVERY_METHODS_INITIAL_INFO}
          title="Default delivery method"
          customDropdownMenu="w-100"
          innerDropdown="mw-100"
          updateFormValue={setValue}
          valueKey="value"
        />
        <ControllerField
          name="configs"
          control={control}
          rules={{ required: false }}
          deliveryMethod={watch('deliveryMethod')}
          type={INPUT_TYPES.INPUT_CONFIG_DELIVERY_TIME_PICKER}
        />
        <PrimaryButton
          text="Save"
          disabled={!formState.isDirty || mutationUpdateThemeDeliveryConfigs.isPending}
          customClass="buttonSaveDeliverySettings"
        />
      </form>
    </section>
  )
}

export default SettingsBlock
