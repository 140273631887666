import { useState } from 'react'
import { isEmpty } from 'lodash'
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { APP_ELEMENTS } from '../../../utils/constants'
import { getUserContents, toggleFavoriteContent } from '../../../api/user'
import { LoadingIcon } from '../../../components/SvgIcon'
import Content from '../../../components/Content'
import Pagination from '../../../components/Pagination'
import EmptyBlock from '../../../components/EmptyBlock'
import SubscriptionList from './components/SubscriptionList'

import './styles.css'

const Home = () => {
  const [selectedFeedPage, setSelectedFeedPage] = useState(1)
  const queryClient = useQueryClient()

  const mutationToggleFavorite = useMutation({
    mutationFn: toggleFavoriteContent,
    onSuccess: (_, contentId) => {
      queryClient.setQueryData(['feed', selectedFeedPage], (oldData) => {
        const listOfContents = oldData?.data?.data?.reduce((acc, item) => {
          if (item.id === contentId) {
            return [...acc, { ...item, isFavorite: !item.isFavorite }]
          }
          return [...acc, item]
        }, [])

        return { data: { ...oldData?.data, data: listOfContents } }
      })
    },
  })

  const { data: { data: contents } = {}, isFetching: isFetchingContent } = useQuery({
    queryKey: ['feed', selectedFeedPage],
    queryFn: () =>
      getUserContents({
        size: 10,
        page: selectedFeedPage,
      }),
    placeholderData: keepPreviousData,
  })

  return (
    <div className="innerHomeContent">
      <div className="innerHomeFeed">
        <h3 className="titleProfileSecond m-0">My Feed</h3>
        <div className="position-relative">
          <div className={`contentList mt-3 ${isFetchingContent ? 'minHeightContentList' : ''}`}>
            {contents?.data?.map(({ content, createdAt, isFavorite, id, contentLink }, index) => (
              <Content
                key={index}
                link={contentLink}
                image={content?.imageIcon}
                isFavorite={isFavorite}
                deliveryDate={createdAt}
                title={content?.subtitle}
                artist={content?.voiceArtist}
                onClickHeart={() => mutationToggleFavorite.mutate(id)}
              />
            ))}
          </div>
          <Pagination
            forcePage={selectedFeedPage - 1}
            total={contents?.total}
            lastPage={contents?.lastPage}
            show={contents?.lastPage > 1}
            innerClassName="mt-sm-2"
            onPageChange={({ selected }) => setSelectedFeedPage(selected + 1)}
          />
          {!isFetchingContent && isEmpty(contents?.data) ? (
            <EmptyBlock
              innerClassName="mt-3"
              title="No Content Yet"
              imageSrc={APP_ELEMENTS.SMALL_LOGO}
              supTitle="Your feed is currently empty, but new content will be here soon!"
            />
          ) : null}
          {isFetchingContent && (
            <div className="innerLoader">
              <LoadingIcon size={60} className="loaderIcon" />
            </div>
          )}
        </div>
      </div>
      <SubscriptionList />
    </div>
  )
}

export default Home
