import { INPUT_TYPES } from '../../../utils/constants'
import ControllerField from '../../../components/HookForm/components/ControllerField'

const ConfirmStep = ({ control, currentStep, onSuccess }) => {
  if (currentStep < 2) {
    return null
  }

  return (
    <div className={currentStep !== 2 ? 'd-none' : ''}>
      <ControllerField
        name="confirmationCode"
        control={control}
        type={INPUT_TYPES.OTP}
        defaultValue=""
        rules={{
          required: currentStep === 2,
        }}
        countAutoSubmit={1}
        isInputNum
        maxCountAutoSubmit={1}
        onSuccess={onSuccess}
        autocomplete="one-time-code"
        shouldAutoFocus
      />
    </div>
  )
}

export default ConfirmStep
