import './styles.css'

const Tag = ({ name, color, className = '' }) => {
  return (
    <p className={`tag ${className}`} style={{ backgroundColor: color }}>
      {name}
    </p>
  )
}

export default Tag
