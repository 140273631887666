import { useCallback, useState } from 'react'

import { LoadingIcon } from '../SvgIcon'

import styles from './MockImage.module.css'

const MockImage = ({ src, imageProps, MockComponent = () => null, defaultShowMockComponent }) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const handleLoad = useCallback(() => {
    setLoading(false)
  }, [])

  const handleError = useCallback(() => {
    setLoading(false)
    setError(true)
  }, [])

  if (error || defaultShowMockComponent) {
    return <MockComponent />
  }

  return (
    <div className="position-relative">
      <img src={src} onLoad={handleLoad} onError={handleError} alt="Mock Image" {...imageProps} />
      {loading && <LoadingIcon className={styles.loaderImg} width={30} height={30} />}
    </div>
  )
}

export default MockImage
