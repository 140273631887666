import moment from 'moment-timezone'
import { startCase } from 'lodash'

export const getTimezoneInfo = (name) => {
  if (!name) {
    return {}
  }

  const zoneAttribute = moment.tz(name).zoneAbbr()
  const cityName = startCase(name?.replace('America/', ''))
  const timezoneOffset = Math.round(moment.tz(name).utcOffset() / 60)
  return {
    title: `${cityName},  ${zoneAttribute}, (UTC ${
      timezoneOffset >= 0 ? '+' : ''
    }${timezoneOffset})`,
    timezoneOffset,
    name,
  }
}

export const getCurrentTimeZone = () => {
  return getTimezoneInfo(moment.tz.guess())
}

export const getAvailableTimezones = () => {
  const americansTimeZones = moment.tz.zonesForCountry('US').map(getTimezoneInfo)
  const currentTimeZone = getCurrentTimeZone()

  const uniqueTimezones = new Set()
  return [currentTimeZone, ...americansTimeZones].filter((timezone) => {
    if (!uniqueTimezones.has(timezone.name)) {
      uniqueTimezones.add(timezone.name)
      return true
    }
    return false
  })
}
