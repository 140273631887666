import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { APP_ELEMENTS, ROLES } from '../../../utils/constants'
import { routes } from '../../../router/routes'

const SuccessScreen = ({ themeName, themeId, userRole }) => {
  return (
    <div className="innerSuccessRegistration">
      <img className="successIcon" src="/images/star.png" alt="Star icon" />
      <p className="titleSuccessRegistration">Thank you for subscribing!</p>
      <p className="descriptionSuccessRegistration">
        You have successfully subscribed to <span className="themeName">{themeName}</span>.
      </p>
      {userRole === ROLES.ROLE_USER && (
        <div className="innerButtons">
          <Link className="primaryButton" to={routes.userHome}>
            View Profile
          </Link>
          <Link className="defaultButton" to={`/account-home/theme-${themeId}`}>
            View Theme
          </Link>
        </div>
      )}
      {userRole === ROLES.ROLE_GUEST && (
        <div className="innerButtons">
          <Link className="primaryButton" to={routes.userLogin} />
        </div>
      )}
      <img className="logo" src={APP_ELEMENTS.DARK_LOGO} alt="Logo" />
    </div>
  )
}

export default connect(({ user }) => ({
  userRole: user.role,
}))(SuccessScreen)
