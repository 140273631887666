import { Controller } from 'react-hook-form'

import DateTimePicker from '../../../../../components/DateTimePicker'

const ControllerWaitingDays = ({ name, control }) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className="innerCountDays">
            <p className="titleCountDays">The specific date the message was sent:</p>
            <DateTimePicker
              innerClassName="innerInputDate"
              valueFormat={'YYYY-MM-DDTHH:mm:ss.SSS[Z]'}
              onChange={onChange}
              error={error}
              value={value}
              displayFormat="MMM dd, yyyy hh:mm"
            />
          </div>
        )}
      />
    </>
  )
}

export default ControllerWaitingDays
