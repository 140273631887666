import { useMemo } from 'react'
import { REGISTRATION_STEPS } from '../../../utils/constants'

const FormFooter = ({ currentStep, onClickBack, isLoading }) => {
  const { isCanBack, isCanSkip } = useMemo(
    () => REGISTRATION_STEPS.find(({ step }) => currentStep === step),
    [currentStep]
  )

  return (
    <div className="footerForm">
      {isCanBack && (
        <button
          onClick={onClickBack}
          type="button"
          className="additionalRegistrationButton btnBack"
        >
          Back
        </button>
      )}
      <div className="actionButtons">
        {isCanSkip && (
          <button type="button" className="additionalRegistrationButton">
            Skip
          </button>
        )}
        <button disabled={isLoading} className="submitButton">
          Confirm
        </button>
      </div>
    </div>
  )
}

export default FormFooter
