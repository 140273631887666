import React, { useEffect, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { omitBy, isEmpty } from 'lodash'
import { useQuery } from '@tanstack/react-query'

import { FORM_CHANGE_USER_DATA } from '../../../../utils/constantsForms'
import { getUserTags, updateUserInfoWithToast } from '../../../../api/user'
import { setNewDataUser } from '../../../../redux/actions/user'
import { isNullOrEmpty } from '../../../../utils/helpers'
import HookForm from '../../../../components/HookForm'
import PrimaryButton from '../../../../components/PrimaryButton'

const UserInformationForm = ({ userData }) => {
  const formRef = useRef(null)
  const dispatch = useDispatch()

  const { data: tags } = useQuery({
    queryKey: ['userTags'],
    queryFn: getUserTags,
  })

  const handleSubmitChangeUserInfo = (data) => {
    const { timezoneOffset, name } = data?.timezone ?? {}
    const filteredData = omitBy(
      {
        ...data,
        interests: data.tags,
        timezoneOffset,
        timezoneName: name,
        gender: data?.gender?.title,
        familyStatus: data?.familyStatus?.title,
      },
      isNullOrEmpty
    )

    updateUserInfoWithToast(filteredData, { success: 'User info saved' })
      .then(() => {
        dispatch(setNewDataUser(filteredData))
        formRef.current?.reset(filteredData)
      })
      .then(() => formRef.current.reset(data))
  }

  const renderFooterFormUserInfo = ({ formState }) => {
    const isDisabled = isEmpty(formState?.dirtyFields)

    return (
      <PrimaryButton
        text="Save"
        disabled={isDisabled}
        customClass="submitButtonUserInfo itemFormUserInfo"
      />
    )
  }

  useEffect(() => {
    formRef.current?.reset({
      ...userData,
      timezone: {
        timezoneOffset: userData?.timezoneOffset,
        title: userData?.timezoneName,
      },
    })
  }, [])

  useEffect(() => {
    if (!isNullOrEmpty(tags?.data)) {
      formRef.current?.setValue(
        'tagsIds',
        tags?.data?.map(({ id }) => id)
      )
    }
  }, [tags])

  return (
    <>
      <HookForm
        className="formUserUpdate"
        ref={formRef}
        fields={FORM_CHANGE_USER_DATA}
        Footer={renderFooterFormUserInfo}
        onSubmit={handleSubmitChangeUserInfo}
      />
    </>
  )
}

export default connect(({ user }) => ({ userData: user.data }))(UserInformationForm)
