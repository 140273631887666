import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { isEmpty, isNil } from 'lodash'
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { APP_ELEMENTS } from '../../../../utils/constants'
import { routes } from '../../../../router/routes'
import {
  getUserContents,
  getUserThemeProgress,
  toggleFavoriteContent,
  getAchievementsCounter,
} from '../../../../api/user'
import { AchievementsCrown, Fire, LoadingIcon } from '../../../../components/SvgIcon'
import Content from '../../../../components/Content'
import Pagination from '../../../../components/Pagination'
import EmptyBlock from '../../../../components/EmptyBlock'

const ContentBlock = ({ isActiveBlock, themeId }) => {
  const [selectedPage, setSelectedPage] = useState(1)
  const enableFetchRequest = useMemo(
    () => !isNil(themeId) && isActiveBlock,
    [isActiveBlock, themeId]
  )
  const queryClient = useQueryClient()

  const mutationToggleFavorite = useMutation({
    mutationFn: toggleFavoriteContent,
    onSuccess: (_, contentId) => {
      queryClient.setQueryData(['contents', themeId, selectedPage], (oldData) => {
        const listOfContents = oldData?.data?.data?.reduce((acc, item) => {
          if (item.id === contentId) {
            return [...acc, { ...item, isFavorite: !item.isFavorite }]
          }
          return [...acc, item]
        }, [])

        return { data: { ...oldData?.data, data: listOfContents } }
      })
    },
  })

  const { data: { data: contents } = {}, isFetching: isFetchingContent } = useQuery({
    queryKey: ['contents', themeId, selectedPage],
    queryFn: () =>
      getUserContents({
        themeId,
        size: 10,
        page: selectedPage,
      }),
    enabled: enableFetchRequest,
    placeholderData: keepPreviousData,
  })

  const { data: { data: achievementsCounter } = {} } = useQuery({
    queryKey: ['userAchievements'],
    queryFn: () => getAchievementsCounter(themeId),
    enabled: enableFetchRequest,
  })
  const { data: { data: streakInfo } = {} } = useQuery({
    queryKey: ['userProgramProgress', themeId],
    queryFn: () => getUserThemeProgress(themeId),
    enabled: enableFetchRequest,
  })

  return (
    <section className={`tabBlock ${isActiveBlock ? 'visibleTabBlock' : ''}`}>
      <div className="d-flex flex-column flex-md-row align-items-center gap-3">
        <Link
          to={routes.userAchievements}
          className="userBlockInfo w-100 cursorPointer hover d-flex align-items-center gap-4 justify-content-between"
        >
          <div>
            <p className="userBlockInfoTitle mb-1">Achievements</p>
            <p className="userBlockSupText">
              Number of achievements:{' '}
              <b>
                {achievementsCounter?.earned}/{achievementsCounter?.totalAchievements}
              </b>
            </p>
          </div>
          <AchievementsCrown size={50} />
        </Link>
        <div className="userBlockInfo w-100 d-flex align-items-center gap-4">
          <Fire size={50} />
          <p className="userBlockInfoTitle">
            <b>{streakInfo?.streak}</b> <span className="userBlockSupText d-block">day streak</span>
          </p>
        </div>
      </div>
      <div className="position-relative">
        <div className={`contentList ${isFetchingContent ? 'minHeightContentList' : ''}`}>
          {contents?.data?.map(({ content, createdAt, isFavorite, id, contentLink }, index) => (
            <Content
              key={index}
              link={contentLink}
              image={content?.imageIcon}
              isFavorite={isFavorite}
              deliveryDate={createdAt}
              title={content?.subtitle}
              artist={content?.voiceArtist}
              onClickHeart={() => mutationToggleFavorite.mutate(id)}
            />
          ))}
        </div>
        <Pagination
          forcePage={selectedPage - 1}
          total={contents?.total}
          lastPage={contents?.lastPage}
          show={contents?.lastPage > 1}
          onPageChange={({ selected }) => setSelectedPage(selected + 1)}
        />
        {!isFetchingContent && isEmpty(contents?.data) ? (
          <EmptyBlock
            innerClassName="mt-3"
            title="Content Coming Soon"
            imageSrc={APP_ELEMENTS.SMALL_LOGO}
            supTitle="Stay tuned—something great is about to arrive!"
          />
        ) : null}
        {isFetchingContent && (
          <div className="innerLoader">
            <LoadingIcon size={60} className="loaderIcon" />
          </div>
        )}
      </div>
    </section>
  )
}

export default ContentBlock
