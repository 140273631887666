import { useCallback } from 'react'

import { DELIVERY_METHODS_INITIAL_INFO } from '../utils/constants'
import CustomDropdown from './CustomDropdown'

const DropdownDeliveryMethod = ({ updateFormValue, onChange, ...rest }) => {
  const handleChangeDeliveryMethod = useCallback((method) => {
    const { listDays } = DELIVERY_METHODS_INITIAL_INFO.find(({ value }) => value === method)
    const updatedDeliveryTimes = listDays.map(({ label }) => ({
      weekday: label,
      deliveryTime: '21:00',
      isSelected: true,
    }))

    updateFormValue('configs', updatedDeliveryTimes)
    onChange(method)
  }, [])

  return <CustomDropdown onChange={handleChangeDeliveryMethod} {...rest} />
}

export default DropdownDeliveryMethod
